<template>
    <div class="fixed flex justify-center inset-0 bg-gray-700 z-10">
        <div class="max-w-md w-full space-y-8 text-center py-12">
            <div class="mx-auto border-4 border-gray-300 w-24 h-24 flex justify-center items-center rounded-full">
                <i class="icon-reading icon-3x text-gray-300"></i>
            </div>
            <div>
                <h5 class="text-xl text-white">Masuk ke akun anda</h5>
                <span class="text-gray-300">Masukkan kredensial Anda di bawah ini</span>
            </div>

            <div class="bg-gray-800 p-5 rounded w-3/4 mx-auto shadow-lg">
                 <loading :active.sync="isLoading" 
                    :is-full-page="false">
                </loading>
                <form @submit.prevent="onSubmit" class="mt-5">
                    <div class="mb-5">
                        <div class="form-feedback form-feedback-left">
                            <input v-model="form.email" type="email" :class="{'border-red-500':error.email}" @keydown="error.email=''" class="form-control bg-gray-600 text-white border-gray-600" placeholder="email">
                            <div class="form-control-feedback">
                                <span>
                                    <i class="icon-mention text-gray-400"></i>
                                </span>
                            </div>
                        </div>
                        <span class="text-red-500 text-left">{{error.email}}</span>
                    </div>
                    <div class="mb-5">
                        <div class="form-feedback form-feedback-left">
                            <input v-model="form.password" type="password" :class="{'border-red-500':error.password}" @keydown="error.password=''" class="form-control text-white border-gray-600 bg-gray-600" placeholder="email">
                            <div class="form-control-feedback">
                                <span>
                                    <i class="icon-lock2 text-gray-400"></i>
                                </span>
                            </div>
                        </div>
                        <span class="text-red-500">{{error.password}}</span>
                    </div>
                    <div>
                        <button type="submit" class="h-9 w-full bg-blue-500 rounded-sm text-white focus:outline-none hover:bg-blue-600">Masuk</button>
                    </div>
                </form>
                <div class="mt-4">
                    <a class="text-blue-500" href="">Lupa passoword?</a>
                </div>
            </div>
            <div>
                <div class="mt-10 text-gray-500">
                    <div>iha.co.id</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    components: {
        Loading
    },
    data () {
        return {
            isLoading: false,
            form: {
                email: 'indoharmonis@gmail.com',
                password: 'pwlan123'
            },
            error: {
                email: '',
                password: ''
            }
        }
    },
    methods: {
        ...mapActions({
            login: 'auth/login'
        }),
        onSubmit () {
            if(!this.form.email) {
                this.error.email = 'Harap masukan email anda!'
            }
            if(!this.form.password) {
                this.error.password = 'Password anda belum diisi!'
            }
            if(this.form.email && this.form.password) {
                this.store()
            }
        },
        async store () {
            this.isLoading = true
            await this.login(this.form)
            .then(() => {
                this.$router.push('/')
                this.isLoading = false
            })
            .catch(error => {
                this.isLoading = false
                this.error.email = error.data.errors.email[0]
                this.error.password = error.data.errors.email[0]
            })
        }
    }
}
</script>